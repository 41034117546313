import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const displayFlag = (label) => {
  switch (label) {
    case "Macedonia":
      return (
        <div className="block sm:mt-0 mt-3 text-black hover:text-cyan-500 ">
          MK
        </div>
      );
    case "English":
      return (
        <div className="block pr-2 text-black hover:text-cyan-500 ">EN</div>
      );
    default:
      return null;
  }
};

const languageMap = {
  en: { label: "English", active: false },
  mk: { label: "Macedonia", active: true },
};

const LanguageSelect = () => {
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem("i18nextLng") || "mk"
  );
  const { t } = useTranslation();

  useEffect(() => {
    i18next.changeLanguage(activeLanguage);
  }, [activeLanguage]);

  const handleLanguageChange = (language) => {
    setActiveLanguage(language);
  };

  return (
    <div>
      <div>
        {Object.keys(languageMap)?.map((item) => (
          <button
            key={item}
            onClick={() => handleLanguageChange(item)}
            className={`${
              activeLanguage === item ? "text-cyan-500" : ""
            } focus:outline-none`}
          >
            {displayFlag(languageMap[item].label)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelect;
