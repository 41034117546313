export default {
  home: "Home",
  product: "Product",
  textM1: "For patients with a body weight",
  textS2: "from 40kg ",
  textS3: "to 60kg",
  textM2: "from 60kg ",
  textM3: "to 75kg",
  textL2: "from 75kg ",
  textL3: "tto100kg",
  textXL2: "from 100kg ",
  textXL3: "to 150kg",
  vision: "Vision",
  aboutnav: "About",
  hero: "Medipro ",
  hero1: " Adult Diapers",
  producttitle: "Medipro Diaper",
  product1:
    "The continence pad MEDIPRO for medium and high degree of incontinence",
  product2: "With a great capacity of absorbency and comfort ",
  product3: "With special elastic bands along the sides for better protection",
  product4:
    "Containing pure cellulose fluff thicker in the middle part with superabsorbent polymers (SAP)",
  product5:
    "Covered with hypoallergenic tissue on the side in contact with the skin",
  product6:
    "This product adheres to be strict manufacturing requirements and standards and European control",
  visiontitle: "Vision",
  visiond:
    "Collaboration is a key part of the success of our company, executed through a clearly defined vision and mission and based on transparency and constant communication.",
  productstitle: "Medipro Products",
  pedtitle: "MediPro Peds Product",
  pedds:
    "The continence pad MEDIPRO for medium and high degree of incontinence, with a great capacity of absorbency and comfort, with special elastic bands along the sides for better protection: containing pure cellulose fluff thicker in the middle part with superabsorbent polymers (SAP), covered with hypoallergenic tissue on the side in contact with the skin. This product adheres to the strict manufacturing requirements and standards and European control. ",
  unpedtitle: "MediPro Underpad Product",
  unpedds:
    "MediPro Underpad is a disposable sanitary layer/ bedding, that offers high protection against uncontrollable spills of liquid, keeping dry the surfaces. MediPro Underpad is available in different sizes according to the needs of the consumers. MediPro Underpad consists of; Polyethylene layers that do not permeate liquid, an abundant layer of plant cellulose, superabsorbent, hot melt adhesive with a pleasant scent, and a non-woven layer, that allows an one-direction penetration of the liquids. This product is ideal for household/domestic and hospital use. MediPro Underpad enjoys certain qualities:has high absorptive, qualities,it is pleasant in skin contact,it is woven in diamond shapes on the surface for a friendly natural feeling of comfort, enabling uniform absorption of liquids.",
  location: "Where to find us?",
  button: "Locations List",
  size: " Different Size",
  about: "About us:",
  aboutd:
    "MEDIPRO brand is built on the pillars of Care, Comfort and Confidence. Care to provide the protection you need. MEDIPRO is brand invented by Tani Company.",
  aboutc: "Medipro - Contact  ",
  aboutem: "E-mail: info@tani-company.com ",
  aboutnr: "Nr: +389 70 389 779",
};
