import React from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// import required modules
import { Autoplay, Pagination } from "swiper";

const HCarousel = () => {
  const { t } = useTranslation();
  return (
    <Swiper
      direction={"vertical"}
      // height="auto"
      autoHeight={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      className="mySwiper"
    >
      <div className="overflow-hidden sm:h-96 h-2">
        <SwiperSlide className="">
          <p className="col-span-1 sm:relative relative font-press-roboto   sm:max-w-xl sm:pl-40 pl-6 sm:pr-2 pr-10 sm:text-left sm:text-lg text-xs  sm:mb-4 mb-0 font-semibold sm:text-cyan-500 text-cyan-500">
            {t("textM1")},
            <br />
            {t("textS2")},
            <br />
            {t("textS3")},
          </p>
        </SwiperSlide>
        <SwiperSlide className=" ">
          <p className="col-span-1 sm:relative relative font-press-roboto   sm:max-w-xl sm:pl-40 pl-6 sm:pr-2 pr-10 sm:text-left sm:text-lg text-xs  sm:mb-4 mb-0 font-semibold sm:text-cyan-500 text-cyan-500">
            {t("textM1")},
            <br />
            {t("textM2")},
            <br />
            {t("textM3")},
          </p>
        </SwiperSlide>
        <SwiperSlide className=" ">
          <p className="col-span-1 sm:relative relative font-press-roboto   sm:max-w-xl sm:pl-40 pl-6 sm:pr-2 pr-10 sm:text-left sm:text-lg text-xs  sm:mb-4 mb-0 font-semibold sm:text-cyan-500 text-cyan-500">
            {t("textM1")},
            <br />
            {t("textL2")},
            <br />
            {t("textL3")},
          </p>
        </SwiperSlide>
        <SwiperSlide className=" ">
          <p className="col-span-1 sm:relative relative font-press-roboto   sm:max-w-xl sm:pl-40 pl-6 sm:pr-2 pr-10 sm:text-left sm:text-lg text-xs  sm:mb-4 mb-0 font-semibold sm:text-cyan-500 text-cyan-500">
            {t("textM1")},
            <br />
            {t("textXL2")},
            <br />
            {t("textXL3")},
          </p>
        </SwiperSlide>
      </div>
    </Swiper>
  );
};

export default HCarousel;
