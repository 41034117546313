import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";

const center = [41.6086, 21.7453];
const markers = [
  { name: "АLОЕ ул. Ѓорче Петров бр.40Б", lat: [41.986, 21.47] },
  { name: "GOLDMUN ул. Џон Кенеди бр.1/11 - Чаир", lat: [42.007, 21.36] },
  { name: "EFEDRA ул. Орце Николов бр. 155", lat: [42.015, 21.43] },
  { name: "LINCURA ул. Булевард Асном бр.28", lat: [42.004, 21.41] },
  { name: "NINEKS FARM (ALTEA) ул. Маршал Тито бр.20", lat: [41.514, 20.96] },
  { name: "HERBA KERCOVE ул.11-ти Септембри", lat: [41.508, 20.96] },
  { name: "М&М ул. Благоја Тоска бр.31-5", lat: [42.004, 20.96] },
  { name: "MERIFARM Ул. 11-ти Октомври бр. Анекс г", lat: [42.01, 20.97] },
  { name: "DITI FARM ул. Мајор Цеде Филиповски бр. 29", lat: [41.791, 20.91] },
  { name: "SEMA FARM ул. Белицица бр.119", lat: [41.792, 20.9] },
  {
    name: "KEMOFARMACIЈА УЛ. 29 Ноември бр. 45",
    lat: [42.00445846132931, 20.963853932260093],
  },
  {
    name: "BIOFARM УЛ. Илинденска бр. 174",
    lat: [42.00186125943516, 20.962259347699973],
  },
  {
    name: "MIAFARM ул. 11-ти Октомври бр.Анекс г",
    lat: [41.34650689920291, 21.56028561720988],
  },
  {
    name: "MEDIKA STRUGA ул. Ристо Крле бр. 1",
    lat: [41.18123192141498, 20.684402023847316],
  },
  { name: "VIVAFARM PRILEP ул. Кривогаштани", lat: [41.35, 21.55] },
  { name: "MENTA FARM ул. Маркскова бр. 190.", lat: [41.338, 21.32] },
  { name: "ZDRAVJE ул. Маршал Тито бб.", lat: [41.738, 22.19] },
  {
    name: "BIO LEK ул. Христијан Карпош бр. 34",
    lat: [41.73834595225746, 22.203537357086528],
  },
  { name: "BLEDI STRUGA ул. Маршал Тито бб.", lat: [41.178, 20.67] },
  { name: "SV VRACI ул. Дејан Бојбода бр. 2 - ОХРИД", lat: [41.121, 20.8] },
  { name: "IRIS ул. Маршал Тито бб.-СТРУМИЦА", lat: [41.437, 22.63] },
  { name: "ZHIVA FARM ул. Благој Ѓорев бр. 93-ВЕЛЕС", lat: [41.717, 21.77] },
  { name: "MARK FARM ул.Доце Божинов бр.48. КУМАНОВО", lat: [42.14, 21.71] },
  {
    name: "DANI FARM ул. МаршалТито бр. 72",
    lat: [41.96831019744898, 20.979524129403828],
  },
  {
    name: "PROFARMA с. Слатино-ТЕАРЦЕ",
    lat: [42.0634930458879, 21.044159853854186],
  },
];

const MyMarkers = ({ data }) => {
  return data.map((marker, index) => {
    return (
      <Marker
        key={index}
        position={marker.lat}
        icon={new Icon({ iconUrl: markerIconPng })}
      >
        <Popup>{marker.name}</Popup>
      </Marker>
    );
  });
};

const MyMap = () => {
  return (
    <MapContainer center={center} zoom={8} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MyMarkers data={markers} />
    </MapContainer>
  );
};

export default MyMap;
