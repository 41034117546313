export default {
  home: "Дома",
  product: "Производ",
  textM1: " За пациенти сотелесна тежина",
  textS2: "од 40кг ",
  textS3: "до 60кг",
  textM2: "од 60кг ",
  textM3: "до 75кг",
  textL2: "од 75кг ",
  textL3: "до 100кг",
  textXL2: "од 100кг ",
  textXL3: "до 150кг",
  vision: "Визија",
  aboutnav: "За нас",
  hero: "Medipro",
  hero1: " Пелени за возрасни може да се подигнат со заверка на тоbар на ФЗОМ",
  producttitle: "Medipro Пелени",
  product1:
    "Medipro пелени за возрасни со умерена, средна и јака инконтиненција",
  product2: "Голем капацитет на впивливост и удобност",
  product3: "Специјални еластични ленти долж страните за подобра заштита",
  product4:
    "Воглавно е составена од целулозно јадро и суперапсорбирачки полимери (SAP)",
  product5:
    "На допирната површина со кожата е обложена со слој кој што останува сув после употребата",
  product6:
    "Овој производ се придржува до строгите производни барања и eвропски стандарди",
  visiontitle: "Визија",
  visiond:
    "Соработката е клучен дел од успехот на нашата компанија, извршена преку јасно дефинирана визија и мисија и базирана на транспарентност и постојана комуникација.",
  productstitle: "Медипро Производи",
  pedtitle: "Medipro Пелени За Bозрасни",
  pedds:
    "Пелената за возрасни Medipro е производ чијашто намена е да го задржи и контролира несаканото уринирање кај пациентите кои боледуваат од ова дисфункција. Воглавно е составена од целулозно јадро и суперапсорбирачки полимери (SAP). На допирната површина со кожата е обложена со слој кој што останува сув после употребата, а надворешно е непробоен полиетилен кој го спречува истекувањето на течностите надвор од абсорбциското јадро. Пелените се придржува кон строгите европски барања и стандарди на производство и контрола. Пред примената на медицинското помагало потребно е да се прочита упатството за употреба на медицинското помагало.",
  unpedtitle: "Medipro Подлоги",
  unpedds:
    "Medipro Underpads е санитарен слој/постелнина за еднократна употреба, кој нуди висока заштита од неконтролирано излевање течност, одржувајќи ги површините суви. Medipro Underpads е достапен во различни големини според потребите на потрошувачите. Medipro Underpads се состои од; Полиетиленски слоеви кои не продираат во течност, обилен слој од растителна целулоза, суперапсорбирачко, топло топено лепило со пријатен мирис и неткаен слој, кој овозможува продирање на течностите во една насока. Овој производ е идеален за домаќинство/домашна и болничка употреба. MediPro Underpad ужива одредени квалитети. 1) има висока апсорптивна моќ 2) Пријатен е при контакт со кожата 3) ткаен е во дијамантски форми на површината за природно чувство на удобност, овозможувајќи рамномерна апсорпција на течности",
  location: "Каде да не најдете?",
  button: "Список на локации",
  size: "Различна големина",
  about: "За нас :",
  aboutd:
    "Medipro е бренд создаден со голема грижа за да понуди удобност и самодоверба. Грижа за да  понуди удобност која вие потребна.",
  aboutc: "Medipro - Контакт ",
  aboutem: "Е-пошта: info@tani-company.com ",
  aboutnr: "Бр: +389 70 389 779",
};
