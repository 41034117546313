import React from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
import product from "./media/product.webp";
import M from "./media/M.webp";
import S from "./media/S.webp";
import XL from "./media/XL.webp";

const HCarousel = () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: false,
      }}
      modules={[Autoplay]}
      className="mySwiper"
    >
      <div className="">
        <SwiperSlide className=" sm:mt-16 mt-0">
          <img
            className=" col-span-1 object-fill sm:w-3/4 w-96 sm:h-auto h-auto sm:ml-16 ml-1"
            src={S}
            alt="image slide 1"
          />
        </SwiperSlide>
        <SwiperSlide className="sm:mt-16 mt-0">
          <img
            className=" col-span-1 object-fill sm:w-3/4 w-96 sm:h-auto h-auto sm:ml-16 ml-1"
            src={M}
            alt="image slide 1"
          />
        </SwiperSlide>
        <SwiperSlide className="sm:mt-16 mt-0">
          <img
            className=" col-span-1 object-fill sm:w-3/4 w-96 sm:h-auto h-auto sm:ml-16 ml-1"
            src={product}
            alt="image slide 1"
          />
        </SwiperSlide>
        <SwiperSlide className="sm:mt-16 mt-0">
          <img
            className=" col-span-1 object-fill sm:w-3/4 w-96 sm:h-auto h-auto sm:ml-16 ml-1"
            src={XL}
            alt="image slide 1"
          />
        </SwiperSlide>
      </div>
    </Swiper>
  );
};

export default HCarousel;
