import React from "react";
import "./App.css";
import HCarousel from "./HCarousel";
import { useState, useEffect } from "react";
import logo from "./media/logo.webp";
import ad from "./media/ad.mp4";
import M from "./media/M.webp";
import product from "./media/product.webp";
import productshape from "./media/productshape.webp";
import S from "./media/S.webp";
import XL from "./media/XL.webp";
import done from "./assets/done.svg";
import eco from "./assets/eco.svg";
import eldery from "./assets/eldery.svg";
import facebook from "./assets/facebook.svg";
import heart from "./assets/heart.svg";
import instagram from "./assets/instagram.svg";
import medication from "./assets/medication.svg";
import water from "./assets/water.svg";
import wp from "./assets/wp.svg";
import Green from "./media/Green.webp";
import Locations from "./media/Locations.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import LanguageSelect from "./LanguageSelect";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import MyMap from "./MyMap";
import VCarousel from "./VerticalCarousel";
import LoadingScreen from "./LoadingScreen";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    AOS.init();
    setTimeout(() => setIsLoading(false), 2000);
  }, []);
  const [activeLink, setActiveLink] = useState("/");
  const { t } = useTranslation();

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 860) {
        setIsNavExpanded(true);
      }
      if (window.innerWidth < 860) {
        setIsNavExpanded(false);
      }
    }
    window.addEventListener("resize", handleResize);
  });
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="">
          <nav class=" fixed bg-white px-2 sm:px-4 py-2.5 z-50 w-screen sm:pl-32 pl-0 sm:pr-32 pr-0  ">
            <div class="container sm:flex flex-wrap items-center justify-between mx-auto  ">
              <div class="sm:flex-1 flex justify-between ">
                <a href="/" class=" items-center">
                  <img
                    src={logo}
                    class="mr-3 sm:h-16 h-12"
                    alt="medipro Logo"
                  />
                </a>
                <div className=" sm:hidden block mr-14">
                  <LanguageSelect />
                </div>
                <button
                  data-collapse-toggle="navbar-default"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                  type="button"
                  class=" items-center mr-4 p-2 text-sm  text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                  aria-controls="navbar-default"
                >
                  <svg
                    class="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <div
                className={isNavExpanded ? "block" : "hidden"}
                class="hidden sm:flex-1 sm:flex sm:justify-end w-screen md:w-auto"
                id="navbar-default"
              >
                <ul class="flex  flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                  <li>
                    <a
                      href="#"
                      className="sm:block hidden py-2 pl-3 pr-4 text-black bg-cyan-500 rounded md:bg-transparent md:text-cyan-500 md:p-0"
                      aria-current="page"
                    >
                      <LanguageSelect />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className={`block py-2 pl-3 pr-4 text-gray-700 rounded active:text-cyan-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-500 md:p-0 ${
                        activeLink === "/home" ? "text-cyan-500 " : ""
                      }`}
                      onClick={() => {
                        setIsNavExpanded(false);
                        setActiveLink("/home");
                      }}
                    >
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#prod"
                      className={`block py-2 pl-3 pr-4 text-gray-700 rounded active:text-cyan-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-500 md:p-0 ${
                        activeLink === "/product" ? "text-cyan-500 " : ""
                      }`}
                      onClick={() => {
                        setIsNavExpanded(false);
                        setActiveLink("/product");
                      }}
                    >
                      {t("product")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#vs1"
                      className={`block py-2 pl-3 pr-4 text-gray-700 rounded active:bg-cyan-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-500 md:p-0 ${
                        activeLink === "/vision" ? "text-cyan-500 " : ""
                      }`}
                      onClick={() => {
                        setIsNavExpanded(false);
                        setActiveLink("/vision");
                      }}
                    >
                      {t("vision")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#abt"
                      className={`block py-2 pl-3 pr-4 text-gray-700 rounded active:text-cyan-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cyan-500 md:p-0 ${
                        activeLink === "/about" ? "text-cyan-500 " : ""
                      }`}
                      onClick={() => {
                        setIsNavExpanded(false);
                        setActiveLink("/about");
                      }}
                    >
                      {t("aboutnav")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <body>
            <header className="">
              <div id="home" className=" sm:h-auto max-h-60 sm:mb-96 mb-0">
                <div className="sm:pt-12 pt-8 grid grid-cols-2 ">
                  <div className="sm:mt-12 mt-12">
                    <div className=" col-span-1 ">
                      <p className="col-span-1 sm:relative relative sm:mt-40 mt-0 font-press-roboto   sm:max-w-xl sm:pl-40 pl-6 sm:pr-2 pr-4 sm:text-left sm:text-xl text-xs font-extrabold sm:text-cyan-500 text-cyan-500">
                        {t("hero")},
                        <br />
                        {t("hero1")},
                      </p>
                      <div className=" col-span-2">
                        <HCarousel />
                      </div>
                    </div>
                  </div>
                  <div className=" sm:mt-0 mt-4 ">
                    <VCarousel />
                  </div>
                </div>
              </div>
            </header>
            <div id="prod" className="sm:pt-32 pt-0">
              <h1
                data-aos="fade-up"
                data-aos-duration="3000"
                className="sm:text-3xl font-press-roboto text-xl sm:mt-32 mt-12 text-center sm:mb-20 mb-10 font-bold text-cyan-500"
              >
                {t("producttitle")}
              </h1>
              <div className=" font-press-roboto  grid sm:grid-cols-6 sm:grid-rows-1 grid-rows-2 mb-20">
                <div
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  className="sm:grid sm:grid-rows-3 row-span-1 sm:col-span-1 sm:col-start-2"
                >
                  <div className="text-box-pr">
                    <img className="sm:mx-0 mx-auto fill-cyan" src={done} />
                    <p className="text-cyan-500 font-medium mt-4 sm:mb-0 mb-4 sm:px-0 px-2 font-press-roboto sm:text-left text-center">
                      {t("product1")}
                    </p>
                  </div>
                  <div className="text-box-pr">
                    <img className="sm:mx-0 mx-auto" src={medication} />
                    <p className="text-cyan-500 font-medium mt-4 sm:mb-0 mb-4 sm:px-0 px-2 font-press-roboto sm:text-left text-center">
                      {t("product2")}
                    </p>
                  </div>
                  <div className="text-box-pr">
                    <img className="sm:mx-0 mx-auto" src={eco} />
                    <p className="text-cyan-500 font-medium mt-4 sm:mb-0 mb-4 sm:px-0 px-4 font-press-roboto sm:text-left text-center">
                      {t("product3")}
                    </p>
                  </div>
                </div>
                <div className="sm:col-span-2 row-span-1 ">
                  <img src={productshape} />
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-duration="3000"
                  className="grid sm:grid-rows-3 col-span-1"
                >
                  <div className="text-box-pl">
                    <img className="sm:mx-0 mx-auto" src={eldery} />
                    <p className="text-cyan-500 font-medium mt-4 sm:mb-0 mb-4 sm:px-0 px-2 font-press-roboto sm:text-left text-center">
                      {t("product4")}
                    </p>
                  </div>
                  <div className="text-box-pl">
                    <img className="sm:mx-0 mx-auto" src={heart} />
                    <p className=" text-cyan-500 font-medium mt-4 sm:mb-0 mb-4 sm:px-0 px-2 font-press-roboto sm:text-left text-center">
                      {t("product5")}
                    </p>
                  </div>
                  <div className="text-box-pl">
                    <img className="sm:mx-0 mx-auto" src={water} />
                    <p className=" text-cyan-500 font-medium mt-4 sm:px-0 px-4 font-press-roboto sm:text-left text-center">
                      {t("product6")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <section data-aos="fade-up" data-aos-duration="3000" id="vs1">
              <div className="sm:grid grid-cols-2 font-press-roboto">
                <div className="col-span-1 bg-cyan-500 p-4">
                  <div className="border-8 border-solid border-white h-full w-full">
                    <h2 className="font-extrabold font-press-roboto sm:pl-72 pl-0  mb-4 text-2xl sm:mt-20 mt-14 sm:mx-auto sm:text-left text-center text-white">
                      {t("vision")}
                    </h2>
                    <p className="font-light font-press-roboto sm:pl-72 px-10 sm:mb-0 mb-10 text-xl sm:mx-auto sm:text-left text-center text-white">
                      {t("visiond")}
                    </p>
                  </div>
                </div>
                <div className="col-span-1">
                  <video
                    muted={true}
                    loop={true}
                    autoPlay={true}
                    controls={false}
                    playsInline
                    src={ad}
                    type="video/mp4"
                  ></video>
                </div>
              </div>
            </section>
            <h1
              data-aos="fade-up"
              data-aos-duration="3000"
              className="sm:text-3xl font-press-roboto text-xl sm:mt-40 mt-20 text-center sm:mb-20 mb-10 font-bold text-cyan-500"
            >
              {t("productstitle")}
            </h1>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="container mx-auto sm:mt-24 mt-10 font-press-roboto"
            >
              <div className="sm:grid grid-cols-2">
                <div className="flex justify-center sm:pl-60 pl-0 p-6 w-auto h-96">
                  <img src={product} />
                </div>
                <div className="sm:mt-24 mt-4">
                  <h2 className="font-extrabold sm:pr-80 px-1 mb-4 text-2xl sm:mx-auto mx-10 sm:text-left text-center sm:text-cyan-500 text-cyan-500">
                    {t("pedtitle")}
                  </h2>

                  <p className="font-light sm:pr-80 sm:px-0 px-12 text-sm sm:mx-auto text-left  sm:text-cyan-500 text-cyan-500">
                    {t("pedds")}
                  </p>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="container mx-auto font-press-roboto"
            >
              <div className="sm:grid grid-cols-2">
                <div className="mt-24">
                  <h2 className="font-extrabold  sm:pl-80 px-1 mb-4 text-2xl sm:mx-auto mx-10 sm:text-left text-center sm:text-cyan-500 text-cyan-500">
                    {t("unpedtitle")}
                  </h2>
                  <p className="font-light sm:pl-80 sm:px-0 px-12 text-sm sm:mx-auto text-left  sm:text-cyan-500 text-cyan-500">
                    {t("unpedds")}
                  </p>
                </div>
                <div className="flex justify-center sm:pr-60 pr-0 p-6 w-auto h-96 mt-10">
                  <img src={Green} />
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="grid grid-rows-2 sm:grid-rows-none sm:grid-cols-2 mt-20 font-press-roboto"
            >
              <div className=" row-span-1 sm:col-span-1">
                <MyMap />
              </div>
              <div className="row-span-1 sm:col-span-1 bg-cyan-500 p-4">
                <div className="border-8 border-solid border-white h-full w-full flex flex-col items-center">
                  <h2 className="font-extrabold font-press-roboto   sm:mb-12 mb-14 sm:text-4xl text-2xl sm:mt-36 mt-14 sm:mx-auto sm:text-center text-center text-white">
                    {t("location")}
                  </h2>
                  <button
                    type="button"
                    class="py-2.5 px-5  mb-20 text-sm font-medium text-cyan-500 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 
              "
                    onClick={() => setShowModal(true)}
                  >
                    {t("button")}
                  </button>
                </div>
              </div>
            </div>
            {showModal ? (
              <>
                <div className="justify-center items-center flex  sm:h-fit h-fit  overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none ">
                  <div className=" z-50 w-fit  my-6 mx-auto   max-w-2xl sm:mt-10 mt-28">
                    {/*content*/}
                    <div className="border-2 border-cyan-500 rounded-lg shadow-lg h-fit relative flex flex-col  sm:w-auto  bg-white  outline-none focus:outline-none ">
                      {/*header*/}

                      {/*body*/}
                      <button
                        onClick={() => setShowModal(false)}
                        className="bg-transparent text-black h-8 w-8 text-4xl text-bold block outline-none focus:outline-none hover:scale-125 "
                      >
                        ×
                      </button>
                      <div className="relative p-6 w-auto h-auto ">
                        <img src={Locations}></img>
                      </div>
                      {/*footer*/}
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
            <section data-aos="fade-up" data-aos-duration="3000">
              <div>
                <h1 className="sm:text-3xl font-press-roboto text-xl mt-20 text-center mb-8 font-bold text-cyan-500">
                  {t("size")}
                </h1>
              </div>
              <div className="sm:grid grid-cols-5">
                <div className="flex justify-center p-6 w-auto h-80">
                  <img src={S} alt="Product S" />
                </div>
                <div className="flex justify-center p-6 w-auto h-80">
                  <img src={M} alt="Product M" />
                </div>
                <div className="flex justify-center p-6 w-auto h-80">
                  <img src={product} alt="Product L" />
                </div>
                <div className="flex justify-center p-6 w-auto h-80">
                  <img src={XL} alt="Product XL" />
                </div>
                <div className="flex justify-center p-6 w-auto h-80">
                  <img src={Green} alt="Product Green" />
                </div>
              </div>
            </section>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              id="abt"
              class="font-press-roboto"
            >
              <div className="bg-cyan-500 p-4">
                <div className="sm:grid grid-cols-2 border-8 border-solid p-2 border-white h-full w-full">
                  <div className="col-span-1">
                    <video
                      muted={true}
                      loop={true}
                      autoPlay={true}
                      controls={false}
                      playsInline
                      src={ad}
                      type="video/mp4"
                    ></video>
                  </div>
                  <div className="col-span-1 flex sm:ml-14 items-center">
                    <div>
                      <h2 className="font-extrabold font-press-roboto mb-4 text-2xl sm:mx-auto mt-4 sm:text-left text-center sm:text-white text-white">
                        {t("about")}
                      </h2>
                      <p className="font-medium font-press-roboto sm:text-l text-xl sm:pr-24 sm:px-0 px-8  sm:mx-auto text-left  text-white">
                        {t("aboutd")}
                      </p>
                      <p className=" sm:mt-4 mt-0font-medium font-press-roboto sm:text-l text-xl sm:pr-24 sm:px-0 px-8  sm:mx-auto text-left  text-white">
                        {t("aboutc")}
                      </p>
                      <p className="font-medium font-press-roboto sm:text-l text-xl sm:pr-24 sm:px-0 px-8  sm:mx-auto text-left  text-white">
                        {t("aboutem")}
                      </p>
                      <p className="font-medium font-press-roboto sm:text-l text-xl sm:pr-24 sm:px-0 px-8  sm:mx-auto text-left  text-white">
                        {t("aboutnr")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" h-auto w-80 mx-auto  sm:mt-4 mt-2">
              <img src={logo} alt="logo" />
            </div>
            <footer className="h-auto font-press-roboto ">
              <div className="sm:mx-auto">
                <div className="block text-sm sm:text-center ">
                  <ul className="flex justify-center ">
                    <li>
                      <a
                        href="https://www.facebook.com/Medipro-102730469194586"
                        target="_blank"
                        className="text-gray-500 hover:text-cyan-500"
                      >
                        <img src={facebook} alt="fb icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/medipro.mk?igshid=MDM4ZDc5MmU="
                        target="_blank"
                        className="text-gray-500 hover:text-gray-900"
                      >
                        <img src={instagram} alt="insta icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="tel:+38970389779"
                        className="text-gray-500 hover:text-gray-900"
                      >
                        <img src={wp} alt="whatsapp" />
                      </a>
                    </li>
                  </ul>
                  <div className=" sm:mb-4 mb-8">
                    <span className="block font-press-roboto font-semibold mt-2 mb-2 text-sm text-cyan-500 sm:text-center text-center ">
                      All Rights Reserved. Powered by{" "}
                      <a
                        href="https://www.instagram.com/checkmate.io/"
                        target="{_blank}"
                        className=" font-press-roboto text-cyan-500 text-base font-extrabold hover:text-buttons"
                      >
                        Checkmate
                      </a>
                      .
                    </span>
                  </div>
                </div>
              </div>
            </footer>
          </body>
        </div>
      )}
    </>
  );
}

export default App;
