import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import mk from "./mk";
import en from "./en";

var li;
navigator.geolocation.getCurrentPosition((position) => {
  const { latitude, longitude } = position.coords;
  const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
  fetch(url)
    .then((response) => response.json())
    .then((result) => {
      console.log(result.countryCode);
      // Use the result to set the appropriate language for i18n
      if (result.countryCode === "MK") {
        li = "mk";
      } else {
        li = "en";
      }
    })
    .catch((error) => {
      //   // Handle the error
    });
});

const resources = {
  en: {
    translation: en,
  },
  mk: {
    translation: mk,
  },
};
i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "mk",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
